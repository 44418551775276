import React, { FunctionComponent, Component } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import headerNav from '../../content/header-nav.en.json';
import headerContact from '../../content/header-contact.en.json';
import messages from '../../content/messages.en.json';

import SEO from '../components/Seo';
import Layout from '../components/layout/Layout';

const IndexPage: FunctionComponent = (location: any) => {
  const data = useStaticQuery(graphql`
    query IndexEnMarkdown {
      site {
        siteMetadata {
          title
          defaultLanguage
          availableLanguages
        }
      }

      content: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//home/en//" } }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            id
            fileAbsolutePath
            frontmatter {
              slug
              title
              source
              position
              file {
                publicURL
              }
            }
            excerpt(format: HTML)
            html
          }
        }
      }

      videos: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//videos//" } }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            id
            fileAbsolutePath
            frontmatter {
              title
              cover {
                publicURL
              }
              videoId
            }
          }
        }
      }

      audios: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//audios//" } }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            id
            fileAbsolutePath
            frontmatter {
              title
              file {
                publicURL
              }
            }
          }
        }
      }

      galleryPhotos: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//gallery//" } }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            id
            fileAbsolutePath
            frontmatter {
              title
              file {
                publicURL
              }
            }
          }
        }
      }

      galleryThumbnails: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//gallery//" } }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              file {
                childImageSharp {
                  fixed(width: 400) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const currentLanguage = 'en';

  return (
    <>
      <SEO
        lang={currentLanguage}
        title="Krzysztof Grzybowski - Clarinettist"
        description="Official website of clarinettist Krzysztof Grzybowski"
        meta={[]}
      />
      <Layout
        data={data}
        headerNav={headerNav}
        headerContact={headerContact}
        messages={messages}
        location={location}
        currentLanguage={currentLanguage}
      />
    </>
  );
};

export default IndexPage;
